import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Layout from "../components/layout/Layout.js"
import { Row } from "../components/row/Row.js"
import { Title } from "../components/title/Title.js"
import { Section } from "../components/section/Section"
import Seo from "../components/seo"
import { Column50 } from "../components/column/Column"
import Cta from "../components/ctablock/Cta"
import { TextMedium } from "../components/text/Text.js"
import featuredImage from '../static/undraw_startup_life_2du2.png'
import BlogSubscribe from "../components/blogSubscribe/BlogSubscribe.js"
import authorImg from "../static/authors/andreeamacoveiciuc.jpeg"
import { Snippet } from "../components/snippet/Snippet.js"

function CaseStudies({ children, isCentered, ...rest }) {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      allContentfulCaseStudy {
        edges {
          node {
            title
            featuredImage{
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
              resize(height: 630, width: 1200) {
                src
              }
            }
            excerpt
            slug
            description {
              childMarkdownRemark {
                timeToRead
              }
            }
            date(formatString: "Do MMMM, YYYY")
            id
          }
        }
        totalCount
      }
    }
  `)

  // const image = data.allContentfulCaseStudy.featuredImage.gatsbyImageData 
   
  return (
    <Layout>
      <Seo
        title="Case Studies"
        description="We share our customer success stories with you, so that you can learn from our experience. Read 
         our latest case studies and get in touch if you face similar challenges."
        image={featuredImage}
        featuredImage={featuredImage}
      />

      <BlogSubscribe/>

      <Section isCentered>
        <Title isPrimary title="Case studies" />
        <Row isCentered>         
          <TextMedium
              textMedium={`${data.allContentfulCaseStudy.totalCount} cases to read`}
            />
        </Row>
        <Row>
          {data.allContentfulCaseStudy.edges.map(({ node }) => (
            <Column50 key={node.id}>
              <Snippet
                snippetTitle={`${node.title}`}
                snippetText={`${node.excerpt}`} 
                authorName="Andreea Macoveiciuc"
                authorTitle="Content Strategist"
                avatarImg={authorImg}
                imageAlt={`${node.title}`}
                snippetImage={node.featuredImage.resize.src}
                href={`/case-studies/${node.slug}/`}
                anchorText="Read the full case study"
                readTime={`${node.description.childMarkdownRemark.timeToRead} min read`}
                publishDate={`${node.date}`} 
              />
            </Column50>
          ))}
        </Row>
      </Section>

      <Cta />
    </Layout>
  )
}

export default CaseStudies
